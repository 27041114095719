.st0, .st1 {
  fill: var(--text-color-main);
}

/* Button start */
.btn {
  width: 220px;
  height: 50px;
  background-color: var(--text-color-main);
  border-radius: 12px;
  padding-left: 15px;
  margin: 50px auto;
  cursor: pointer;
  position: relative;
}

.cta {
  top: 0;
  left: 1.5rem;
  font-size: 1.8rem;
  z-index: 1;
  position: absolute;
  color: var(--background-color);
  font-weight: 700;
  letter-spacing: 0.02em;
}

/* DROPS START */
.dropsStart {
  top: 50px;
  position: absolute;
  transform-origin: 50% 0%;
  transform: scaleY(1); /* Inverted: Initially, the drops are visible */
  transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.btn:hover > .dropsStart {
  transform-origin: 50% 0%;
  transform: scaleY(0); /* Inverted: On hover, the drops collapse */
  transition-duration: 250ms;
}

/* DROPS */
.drops {
  top: 40px; /* Inverted: Initially, the drops are in the final position */
  position: absolute;
  transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.btn:hover > .drops {
  top: 0; /* Inverted: On hover, the drops move up to the initial position */
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
}
