.st0,
.st1 {
  fill: var(--text-color-main);
}

.btn {
  border: none;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 50px;
  background-color: var(--text-color-main);
  border-radius: 12px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  position: relative;
  z-index: 30;
  display: inline-flex; 
  align-items: center; 
  justify-content: center; 
  min-width: 120px; 
  max-width: 100%; 
  white-space: nowrap; 
}

.cta {
  z-index: 1;
  font-size: 1.6rem;
  color: var(--background-color);
  font-weight: 700;
  letter-spacing: .02em;
}

/* DROPS START */
.dropsStart {
  top: 50px;
  position: absolute;
  transform-origin: 50% 0%;
  transform: scaleY(1); /* Inverted: Initially, the drops are visible */
  transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.btn:hover > .dropsStart {
  transform-origin: 50% 0%;
  transform: scaleY(0); /* Inverted: On hover, the drops collapse */
  transition-duration: 250ms;
}

/* DROPS */
.drops {
  top: 40px; /* Inverted: Initially, the drops are in the final position */
  position: absolute;
  transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.btn:hover > .drops {
  top: 0; /* Inverted: On hover, the drops move up to the initial position */
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
}
